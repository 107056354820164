<template>
	<v-app>
		<v-main>
			<router-view :key="$route.fullPath"></router-view>
		</v-main>
	</v-app>
</template>

<script>
	export default {
		name: "App",
		data() {
			return { isLoading: true };
		},
		metaInfo() {
			return {
				title: "ER - 1",
				meta: [
					{ name: "description", content: "Web App ER - 1" },
					{ property: "og:title", content: "Consultation WebApp" },
					{ property: "og:site_name", content: "ER-1" },
					{ property: "og:type", content: "Web-app" },
					{ name: "robots", content: "index,follow" },
				],
			};
		},
		mounted() {},
	};
</script>

<style>
	@import "./assets/css/style.scss";
</style>
