<template>
	<div class="product">
		<v-app-bar>
			<v-row style="">
				<v-col cols="3" class="py-4 px-0">
					<button
						type="button"
						@click="hasHistory() ? $router.go(-1) : $router.push('/')"
						class="btn btn-outline-success"
					>
						<v-icon>mdi-chevron-left</v-icon> Back
					</button>
				</v-col>
				<v-col class="d-flex justify-space-around">
					<v-toolbar-title>Produk er-1</v-toolbar-title>
				</v-col>
				<v-col cols="3" class="d-flex justify-end"> </v-col>
			</v-row>
		</v-app-bar>
		<v-skeleton-loader
			type="image"
			:loading="loading"
			v-if="firstLoad"
		></v-skeleton-loader>
		<v-carousel
			hide-delimiters
			cycle
			height="200"
			hide-delimiter-background
			show-arrows-on-hover
			class="slider_home"
			v-show="!firstLoad"
		>
			<v-carousel-item
				v-for="(slider, i) in sliders"
				:key="i"
				:src="slider.value"
				:href="slider.link"
			>
			</v-carousel-item>
		</v-carousel>
		<v-card class="mx-auto" max-width="500" style="padding-top: 10px">
			<v-container fluid>
				<div
					v-for="(category, c) in produk"
					:key="c"
					v-show="category.category.produk.length > 0"
				>
					<h2>{{ category.category.name }}</h2>
					<v-row dense class="mb-5">
						<v-col
							class="box"
							v-for="card in category.category.produk"
							:key="card.title"
							:cols="6"
						>
							<v-skeleton-loader
								type="image, list-item-two-line"
								:loading="loading"
								v-if="firstLoad"
							></v-skeleton-loader>
							<v-card
								@click="$router.push('/produk/' + card.id)"
								class="product-card"
								v-show="!firstLoad"
							>
								<img
									:src="card.image"
									class="black--text align-end"
									gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
									height="250px"
								/>
								<v-card-title
									v-text="card.nama"
									class="product-title pb-0"
								></v-card-title>
								<v-card-text v-text="card.deskripsi" class="pb-0"></v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</div>
			</v-container>
		</v-card>
		<BottomNav />
	</div>
</template>
<script>
	import BottomNav from "../../components/BottomNav.vue";
	import helpers from "../../helpers/auth";
	import { GetProduct, GetSliderProduct } from "../../services/api";
	import "./product.view.scss";

	export default {
		name: "Product",
		components: {
			BottomNav,
		},
		methods: {
			hasHistory() {
				return window.history.length > 2;
			},
		},
		data: () => ({
			produk: [],
			cards: [
				{
					color: "#1F7087",
					src: require("../../assets/11-2@2x.jpg"),
					title: "Brightening Cream",
					slug: "brightening-cream",
					type: "BRIGHTENING",
				},
				{
					color: "#952175",
					src: require("../../assets/11@2x.jpg"),
					title: "Brightening Serum",
					slug: "brightening-serum",
					type: "BRIGHTENING",
				},
				{
					color: "#952175",
					src: require("../../assets/11-3@2x.jpg"),
					title: "Brightening Sunblock",
					slug: "brightening-sunblock",
					type: "BRIGHTENING",
				},
				{
					color: "#1F7087",
					src: require("../../assets/11-4@2x.jpg"),
					title: "Brightening Toner",
					slug: "brightening-toner",
					type: "BRIGHTENING",
				},
			],
			sliders: [],
			loading: true,
			firstLoad: true,
		}),
		computed: {
			userData() {
				return helpers.getData();
			},
		},
		mounted() {
			GetProduct()
				.then((res) => {
					this.produk = res.data.data;
					console.log(this.produk);
					setTimeout(() => {
						if (this.firstLoad) this.firstLoad = false;
						this.loading = false;
					}, 1000);
				})
				.catch((err) => {
					console.log(err);
				});
			GetSliderProduct()
				.then((res) => {
					this.sliders = res.data.data;
					console.log(this.sliders);
				})
				.catch((err) => {
					console.log(err);
				});
		},
	};
</script>
