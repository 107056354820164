<template>
	<div id="Editprofile">
		<v-app-bar class="subtitle-bar white elevation-0">
			<v-row>
				<button
					type="button"
					@click="hasHistory() ? $router.go(-1) : $router.push('/')"
					class="btn btn-outline-success"
				>
					<v-icon>mdi-chevron-left</v-icon> Back
				</button>
			</v-row>
		</v-app-bar>
		<v-container class="box-edit-profile">
			<h2 class="text-center">Update Profile</h2>
			<v-card class="py-2">
				<div v-if="imageData.length == 0">
					<v-img
						max-height="100"
						max-width="100"
						@error="setAltImg"
						:src="
							profile.image == null
								? 'https://i.ibb.co/6YjQ3GD/blank-profile.png'
								: profile.image
						"
						style="border-radius: 100% !important; margin: 20px auto"
						@click="chooseImage"
					></v-img>
				</div>
				<div id="preview" v-if="imageData.length > 0">
					<v-img
						max-height="100"
						max-width="100"
						:src="imageData"
						style="border-radius: 100% !important; margin: 20px auto"
						@click="chooseImage"
					></v-img>
				</div>
				<!-- <input
          class="file-input"
          ref="fileInput"
          type="file"
          @input="onSelectFile"
        /> -->
				<input
					type="file"
					ref="fileInput"
					class="file-input"
					accept="image/*"
					@change="onSelectFile"
				/>
			</v-card>
			<v-form v-on:submit.prevent="editProfile">
				<v-text-field
					filled
					name="nama"
					label="Nama"
					id="profile_name"
					v-model="profile.nama"
					color="orange accent-2"
				></v-text-field>
				<v-text-field
					filled
					id="profile_no_hp"
					name="notelepon"
					label="No Telephone"
					color="orange accent-2"
					v-model="profile.no_hp"
				></v-text-field>
				<v-radio-group v-model="profile.gender" row>
					<v-radio label="Laki-laki" value="L"></v-radio>
					<v-radio label="Perempuan" value="P"></v-radio>
				</v-radio-group>
				<v-menu
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="profile.tanggal_lahir"
							label="Tanggal Lahir"
							prepend-icon="mdi-calendar"
							readonly
							v-bind="attrs"
							v-on="on"
							name="umur"
						></v-text-field>
					</template>
					<v-date-picker
						ref="picker"
						v-model="profile.tanggal_lahir"
						:max="new Date().toISOString().substr(0, 10)"
						min="1950-01-01"
					></v-date-picker>
				</v-menu>
				<v-text-field
					id="profile.berat"
					filled
					name="berat"
					label="Berat"
					color="orange accent-2"
					v-model="profile.berat"
				></v-text-field>
				<v-alert type="error" v-show="response !== null">{{
					response
				}}</v-alert>
				<v-alert type="success" v-show="responsesuccess !== null">
					{{ responsesuccess }}</v-alert
				>
				<v-btn
					block
					color="white--text"
					class="btn-detail"
					style="
            text-transform: unset !important;
            box-shadow: 0 4px rgb(60 50 48/18%) !important;
          "
					@click="EditProfile()"
				>
					Edit
				</v-btn>
			</v-form>
		</v-container>
		<BottomNav />
	</div>
</template>
<script>
	import { required, email, sameAs } from "vuelidate/lib/validators";
	import BottomNav from "../components/BottomNav.vue";
	import helpers from "../helpers/auth";
	import { GetProfile, EditProfile, EditProfileImage } from "../services/api";
	export default {
		name: "editprofile",
		components: { BottomNav },
		data: () => ({
			value: 1,
			drawer: null,
			profile: [],
			response: null,
			responsesuccess: null,
			menu: false,
			imageData: "",
			imageFile: "",
			profile_update: {
				name: "",
				email: "",
				no_hp: "",
				gender: "",
				password: "",
				birth_of_date: "",
				weight: "",
			},
			edit_profile_data: {
				nama: "",
				no_hp: "",
				gender: "",
				email: "",
				tanggal_lahir: "",
				password: "",
				berat: "",
			},
			profile_data: {
				nama: "",
				no_hp: "",
				gender: "",
				email: "",
				tanggal_lahir: "",
				password: "",
			},
		}),
		validations: {
			profile: {
				nama: { required },
				no_hp: { required },
				gender: { required },
				email: { required, email },
				tanggal_lahir: { required },
				password: { required },
				confirm_password: { required, sameAsPassword: sameAs("password") },
			},
		},
		computed: {
			userData() {
				return helpers.getData();
			},
		},
		mounted() {
			GetProfile(this.userData.token)
				.then((res) => {
					this.profile = res.data.data;
					console.log(this.profile);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		methods: {
			hasHistory() {
				return window.history.length > 2;
			},
			setAltImg() {
				this.profile.image = "https://i.ibb.co/6YjQ3GD/blank-profile.png";
			},
			chooseImage() {
				this.$refs.fileInput.click();
			},
			onSelectFile() {
				const input = this.$refs.fileInput;
				const files = input.files;
				if (files && files[0]) {
					const reader = new FileReader();
					reader.onload = (e) => {
						console.log(e);
						this.imageData = e.target.result;
						//console.log(this.imageData);
					};
					this.imageFile = files[0];
					reader.readAsDataURL(files[0]);
					this.$emit("input", files[0]);
				}
			},
			EditProfile() {
				this.submitted = true;
				this.edit_profile_data.nama = this.profile.nama;
				this.edit_profile_data.no_hp = this.profile.no_hp;
				this.edit_profile_data.gender = this.profile.gender;
				this.edit_profile_data.email = this.profile.email;
				this.edit_profile_data.tanggal_lahir = this.profile.tanggal_lahir;
				this.edit_profile_data.berat = this.profile.berat;

				// stop here if form is invalid
				// this.$v.$touch();
				// if (this.$v.$invalid) {
				//   console.log('masuk invalid')
				//   return;
				// } else {
				console.log("masuk valid", this.edit_profile_data);
				EditProfile(this.edit_profile_data, this.userData.token)
					.then((data) => {
						console.log(data);
						if (data.status == 200) {
							console.log(data.data.message);
							this.responsesuccess = data.data.message;
							setTimeout(() => {
								this.$router.push("/profile");
							}, 2000);
							return;
						}
					})
					.catch((err) => {
						console.log(err);
						if (err.response.status == 400) {
							console.log(err.response.data.message);
							this.response = err.response.data.message;
							return;
						}
					});

				if (this.imageData != null) {
					let formData = new FormData();
					formData.append("image", this.imageFile);
					EditProfileImage(formData, this.userData.token)
						.then((res) => {
							console.log(res.message);
						})
						.catch((err) => {
							console.log(err);
						});
				}
				// }
			},
		},
		watch: {
			menu(val) {
				val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
			},
		},
	};
</script>
<style>
	#Editprofile .v-card.v-sheet.theme--light {
		box-shadow: none;
	}
	#Editprofile,
	#Editprofile .box-edit-profile,
	#Editprofile .theme--light.v-card {
		background: #f8f8f8;
	}
	.btn-detail {
		background-color: #3c3230 !important;
	}
	input.file-input {
		display: none;
	}
	#Editprofile .v-input--selection-controls__input {
		display: none;
	}

	#Editprofile .v-input--radio-group--row .v-input--radio-group__input {
		justify-content: normal;
	}
</style>
