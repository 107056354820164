<template>
	<div class="list-history">
		<v-container class="pb-50">
			<div v-show="riwayat.length > 1">
				<button
					type="button"
					@click="hasHistory() ? $router.go(-1) : $router.push('/')"
					class="my-2 btn btn-outline-success"
				>
					<v-icon>mdi-chevron-left</v-icon> Back
				</button>
				<v-card-title class="font-er-1 font-weight-bold"
					>Riwayat Diagnosa</v-card-title
				>
			</div>
			<div class="card-group" v-for="riwayat in riwayat" :key="riwayat.id">
				<v-skeleton-loader
					:loading="loading"
					v-if="firstLoad"
					type="list-item-avatar, list-item-three-line, card-heading, image, actions"
				></v-skeleton-loader>
				<v-card
					class="mx-auto mb-2 elevation-1"
					max-width="400"
					style="
            padding-bottom: 7px !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
          "
					v-show="!firstLoad"
				>
					<v-container class="pb-0">
						<v-row class="v-align-center">
							<v-col cols="7">
								<h3 class="title" v-text="riwayat.pasien.nama"></h3
							></v-col>
							<v-col cols="5">
								<p class="float-right">
									{{ riwayat.created_at | moment }}
								</p></v-col
							>
						</v-row>
						<v-list-item three-line>
							<v-list-item-avatar
								rounded-1
								tile
								width="100"
								height="125"
								color="grey"
							>
								<v-img :src="riwayat.konsultasi_image[0].gambar"></v-img>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-subtitle>
									<label for="" class="font-weight-bold">Tanggal Lahir :</label>
									<p>{{ riwayat.pasien.tanggal_lahir | moment }}</p>
								</v-list-item-subtitle>
								<v-list-item-subtitle>
									<label for="" class="font-weight-bold">Jenis Kelamin :</label>
									<p v-text="riwayat.pasien.gender"></p>
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-container>
					<v-container>
						<v-divider class="pb-3"></v-divider>
						<v-list-item-subtitle
							v-for="(konsul, k) in riwayat.konsultasi_meta"
							:key="k"
						>
							<label for="">{{
								k === 1
									? "Keluhan Kedua"
									: k === 2
									? "Keluhan Ketiga"
									: k === 3
									? "Keluhan Keempat"
									: k === 4
									? "Keluhan Kelima"
									: "Keluhan Pertama"
							}}</label>
							<p>{{ konsul.complaint }} {{ konsul.face_area }}</p>
						</v-list-item-subtitle>
						<v-card-actions style="padding-top: 23px">
							<v-btn
								@click="$router.push('/riwayat/' + riwayat.id)"
								:disabled="riwayat.working_diagnosis === ''"
								block
								color="white--text"
								class="btn-detail"
								style="
                  text-transform: unset !important;
                  box-shadow: 0 4px rgb(60 50 48/18%) !important;
                "
							>
								Lihat detil
							</v-btn>
						</v-card-actions>
					</v-container>
				</v-card>
			</div>
			<v-container
				v-show="!firstLoad && riwayat.length == 0"
				class="text-center text--secondary mx-auto pt-5"
				fill-height
			>
				<v-row align="center" justify="center">
					<div class="p-5">
						<v-img
							src="../../assets/diagnosa@2x.png"
							width="180"
							class="ma-auto"
						></v-img>
					</div>
					<p class="headline font-weight-bold mt-4 mb-4">
						Riwayat diagnosa kosong
					</p>
					<p class="mb-5">
						Anda belum mempunyai riwayat diagnosa. Silahkan memulai konsultasi
						dengan dokter kecantikan kami untuk memperoleh riwayat diagnosa baru
					</p>
					<v-btn
						@click="$router.push('konsultasi')"
						block
						color="white--text"
						class="btn-beli-produk"
						style="box-shadow: 0 4px rgb(60 50 48/18%) !important"
					>
						Konsultasi Sekarang
					</v-btn>
				</v-row>
			</v-container>
			<BottomNav />
		</v-container>
	</div>
</template>

<script>
	import BottomNav from "../../components/BottomNav.vue";
	import "./history.view.scss";
	import { GetKonsultasiList } from "../../services/api";
	import helpers from "../../helpers/auth";
	import moment from "moment";

	export default {
		components: {
			BottomNav,
		},
		data() {
			return {
				riwayat: [],
				riwayats: [
					{
						id: 1,
						name: "Juan A Kartawidjaya",
						slug: "juan-a-kartawidyaya",
						datemember: "24 Juli 2020",
						gender: "Laki-laki",
						brithday: "10 Agustus 1988",
						keluhan: "Bisul",
						area: "pipi sebelah kanan",
					},
					{
						id: 2,
						name: "Syihab",
						slug: "syihab",
						gender: "Laki-laki",
						datemember: "24 Juli 2020",
						brithday: "10 Agustus 1988",
						keluhan: "Jerawat",
						area: "pipi sebelah kanan",
					},
					{
						id: 3,
						name: "Adhi Sucianto",
						slug: "adhi-sucianto",
						gender: "Laki-laki",
						datemember: "24 Juli 2020",
						brithday: "10 Agustus 1988",
						keluhan: "Jamur",
						area: "pipi sebelah kanan",
					},
					{
						id: 4,
						name: "Kurniawan Setyo",
						slug: "kurniawan-setyo",
						gender: "Laki-laki",
						datemember: "24 Juli 2020",
						brithday: "10 Agustus 1988",
						keluhan: "Ketombe",
						area: "pipi sebelah kanan",
					},
				],
				loading: true,
				firstLoad: true,
			};
		},
		computed: {
			userData() {
				return helpers.getData();
			},
		},
		mounted() {
			this.getKonsultasiList();
		},
		filters: {
			moment(date) {
				return moment(date)
					.locale("id")
					.format("D MMMM YYYY");
			},
		},
		methods: {
			hasHistory() {
				return window.history.length > 2;
			},
			getKonsultasiList() {
				GetKonsultasiList(this.userData.token)
					.then((res) => {
						console.log(res);
						this.riwayat = res.data.data;
						setTimeout(() => {
							if (this.firstLoad) this.firstLoad = false;
							this.loading = false;
						}, 1000);
					})
					.catch((err) => {
						console.log(err);
					});
			},
		},
	};
</script>
