<template>
	<div class="wrapper">
		<video
			class="video"
			:class="facingMode === 'user' ? 'front' : ''"
			ref="video"
		/>
		<canvas style="display: none" ref="canva" />

		<div class="face-area"></div>
		<div class="content-area">
			<div class="text-layer-wrapper">
				<div class="text-layer">
					Relaxkan wajah kamu dan jangan tersenyum saat pengambilan foto
				</div>
				<div class="text-layer">
					Usahakan Pencahayaan yang sama untuk setiap foto
				</div>
				<div class="text-layer">
					Bersihkan wajah secara menyeluruh dan jangan aplikasikan produk apapun
				</div>
				<div class="text-layer">
					Foto anda akan kami simpan secara aman dan data anda tidak akan kami
					disebarkan
				</div>
			</div>
			<div class="photo-button-container">
				<button
					class="button photo-button"
					@click="TakePhoto"
					:disabled="max_count"
					v-if="photosLength < 1"
				>
					Ambil foto bagian depan wajah
				</button>
				<button
					class="button photo-button"
					@click="TakePhoto"
					:disabled="max_count"
					v-if="photosLength >= 1"
				>
					Ambil foto bagian wajah yang memiliki keluhan
				</button>
			</div>
			<button class="btn-upload" @click="upload()">Upload Gambar</button>
			<button
				class="button is-rounded is-outlined icon-switch"
				@click="switchCamera"
			></button>
		</div>
	</div>
</template>

<script>
	import "./camera.component.scss";

	export default {
		name: "Camera",
		components: {},
		data() {
			return {
				photos: [],
				photosLength: 0,
				mediaStream: null,
				max_count: false,
				videoDevices: [],
				facingMode: "environment",
				counter: 0,
				max_photos: false,
				switchingCamera: false,
				from: "",
				temp_photos: [],
			};
		},
		methods: {
			async StartRecording(facingMode) {
				this.facingMode = facingMode;
				let video = this.$refs.video;
				this.mediaStream = await navigator.mediaDevices.getUserMedia({
					video: { facingMode: facingMode },
				});
				video.srcObject = this.mediaStream;
				return await video.play();
			},
			async TakePhoto() {
				let video = this.$refs.video;
				let canva = this.$refs.canva;
				let width = video.videoWidth;
				let height = video.videoHeight;
				canva.width = width;
				canva.height = height;
				let ctx = canva.getContext("2d");
				ctx.save();

				if (this.facingMode === "user") {
					ctx.scale(-1, 1);
					ctx.drawImage(video, width * -1, 0, width, height);
				} else {
					ctx.drawImage(video, 0, 0);
				}

				ctx.restore();
				console.log(canva);
				this.photos.push({
					id: this.counter++,
					src: canva.toDataURL("image/png"),
				});
				this.photosLength = this.photos.length;
				console.log(this.photos);
				if (this.photos.length == 2) {
					this.max_count = true;
				}
				console.log(this.max_count);
			},
			async switchCamera() {
				this.switchingCamera = true;
				const tracks = this.mediaStream.getVideoTracks();
				tracks.forEach((track) => {
					track.stop();
				});
				await this.StartRecording(
					this.facingMode === "environment" ? "user" : "environment"
				);
				this.switchingCamera = false;
			},
			upload() {
				if (this.temp_photos.length > 0) {
					this.photos = [...this.photos, ...this.temp_photos];
				}
				console.log(this.photos);
				// localStorage.setItem('photos', JSON.stringify(this.photos))
				const tracks = this.mediaStream.getVideoTracks();
				tracks.forEach((track) => {
					track.stop();
					this.$router.push({
						name: this.from,
						params: {
							photos: this.photos,
						},
					});
				});
			},
		},
		async mounted() {
			this.from = this.$route.params.from;
			this.temp_photos = this.$route.params.photos;
			console.log(this.from);
			console.log(this.temp_photos);

			const devices = await navigator.mediaDevices.enumerateDevices();
			this.videoDevices = devices.filter((d) => d.kind === "videoinput");
			await this.StartRecording(
				this.videoDevices.length === 1 ? "user" : "environment"
			);
		},
	};
</script>
