<template>
  <v-snackbar v-model="show" color="green">
    {{ text }}
  </v-snackbar>
</template>
<script>
export default {
  name: "Snackbar",
  props: ["text", "show"],
};
</script>