<template>
	<div class="profile">
		<v-container>
			<h1 class="say-hi">Hello, {{ profile.nama }}</h1>
			<v-skeleton-loader
				:loading="loading"
				v-if="firstLoad"
				type="list-item-avatar, list-item-three-line"
			></v-skeleton-loader>
			<v-row no-gutters class="list-profile" v-if="!firstLoad">
				<v-col>
					<v-card class="py-2 photo-profile">
						<v-img
							max-height="150"
							max-width="100"
							:src="profile.image"
							@error="imgError"
						></v-img>
					</v-card>
				</v-col>
				<v-col>
					<v-card class="pa-2">
						<p class="label font-weight-bold">Nomor Telepon</p>
						<p>{{ profile.no_hp }}</p>
						<p class="label font-weight-bold">Umur</p>
						<p>{{ profile.umur }}</p>
					</v-card>
				</v-col>
				<v-col>
					<v-card class="pa-2">
						<p class="label font-weight-bold">Gender</p>
						<p v-show="profile.gender == 'L'">Laki-laki</p>
						<p v-show="profile.gender == 'P'">Perempuan</p>
						<p class="label font-weight-bold">Berat</p>
						<p>{{ profile.berat }}kg</p>
					</v-card>
				</v-col>
			</v-row>

			<v-list dense>
				<v-list-item v-for="item in items" :key="item.title" :to="item.link">
					<v-skeleton-loader
						:loading="loading"
						v-if="firstLoad"
						type="avatar"
					></v-skeleton-loader>
					<v-list-item-avatar v-if="!firstLoad">
						<v-img
							max-height="34"
							max-width="34"
							:src="require(`../../assets/img/profile/${item.icon}`)"
						></v-img>
					</v-list-item-avatar>
					<v-skeleton-loader
						:loading="loading"
						v-if="firstLoad"
						type="text"
					></v-skeleton-loader>
					<v-list-item-content v-if="!firstLoad">
						<v-list-item-title
							>{{ item.title }}
							<v-icon>mdi-chevron-right</v-icon></v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-container>

		<BottomNav />
	</div>
</template>
<script>
	import BottomNav from "../../components/BottomNav.vue";
	import "./profile.view.scss";
	import helpers from "../../helpers/auth";
	import { GetProfile } from "../../services/api";
	import { AUTH_LOGOUT } from "../../store/actions/auth";
	export default {
		name: "profile",
		components: { BottomNav },
		methods: {
			hasHistory() {
				return window.history.length > 2;
			},
			imgError() {
				this.profile.image = "https://i.ibb.co/6YjQ3GD/blank-profile.png";
			},
		},
		data: () => ({
			haveData: false,
			profile: [],
			value: 1,
			drawer: null,
			items: [
				{
					title: "Edit Profil",
					icon: "ic-profile@2x.png",
					link: "/edit-profile/",
				},
				{
					title: "Resep Digital",
					icon: "resep-digital.png",
					link: "/invoice/",
				},
			],
			loading: true,
			firstLoad: true,
		}),
		mounted() {
			//console.log(this.userData.token);
			GetProfile(this.userData.token)
				.then((res) => {
					this.profile = res.data.data;
					console.log(this.profile);
					setTimeout(() => {
						if (this.firstLoad) this.firstLoad = false;
						this.loading = false;
					}, 1000);
				})
				.catch((err) => {
					console.log(err);
					this.$store.dispatch(AUTH_LOGOUT).then(() => {
						this.$router.push("/login");
					});
				});
		},
		computed: {
			userData() {
				return helpers.getData();
			},
			img_profile_url: function() {
				var img_profile = this.profile.image;
				if (img_profile !== null) {
					return {
						img_profile,
					};
				} else {
					return "https://i.ibb.co/6YjQ3GD/blank-profile.png";
				}
			},
		},
	};
</script>

<style scoped>
	.row.list-profile p.label {
		margin-bottom: 0;
	}
	.v-list-item__title i {
		float: right;
	}
	.profile h1 {
		color: #3c3230;
		font-size: 31px;
		font-weight: 700;
		padding-bottom: 13px;
	}
	.profile {
		background: #f8f8f8;
		min-height: 100vh;
		padding-top: 35px;
	}
	.profile .v-sheet.v-card,
	.profile .v-sheet.v-list {
		background: #f8f8f8;
		box-shadow: none !important;
	}
	p.label.font-weight-bold {
		color: #050505;
		font-size: 16px;
	}
	.profile p {
		font-size: 16px;
		color: #515151;
	}
	.profile .theme--light.v-list-item--active:hover::before,
	.theme--light.v-list-item--active::before {
		opacity: 0 !important;
	}
	.profile a.v-list-item--active.v-list-item.v-list-item--link.theme--light {
		border-bottom: 1px solid #d5d5d5;
	}
	.profile a.v-list-item {
		display: flex;
		align-items: center;
	}
	.profile .v-list-item__title {
		font-size: 18px !important;
		line-height: normal;
	}
</style>
