import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/profile/profile.view.vue";
import Konsultasi from "../views/konsultasi/konsultasi.view.vue";
import Product from "../views/product/product.view.vue";
import History from "../views/history/history.view.vue";
import Detailriwayat from "../components/DetailRiwayat.vue";
import DetailProduct from "../components/detail-product/detail-product.component.vue";
import Detailinvoice from "../components/detail-invoice/detailinvoice.component.vue";
import Invoice from "../components/invoice/invoice.component.vue";
import Editprofile from "../components/EditProfile.vue";
import Camera from "../components/camera/camera.component.vue";
import store from "../store";
import ForgotPassword from "../views/ForgotPassword.vue";
import Contact from "../views/Contact-us.vue";
import PageNotFound from "../views/404.vue";

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
	if (!store.getters.isAuthenticated) {
		next();
		return;
	}
	next("/");
};

const ifAuthenticated = (to, from, next) => {
	if (store.getters.isAuthenticated) {
		next();
		return;
	}
	console.log(store.getters.isAuthenticated);
	next("/welcome");
};

const router = new VueRouter({
	//   mode: 'history',
	//   base: process.env.BASE_URL,
	routes: [
		{
			path: "/login",
			name: "login",
			component: () => import("../views/Login.vue"),
			beforeEnter: ifNotAuthenticated,
		},
		{
			path: "/register",
			name: "register",
			component: () => import("../views/Register.vue"),
		},
		{
			path: "/verifikasi",
			name: "verifikasi",
			component: () => import("../views/Verifikasi.vue"),
		},
		{
			path: "/",
			name: "Home",
			component: Home,
		},
		{
			path: "/diagnosa",
			name: "diagnosa",
			component: () => import("../views/Diagnosa.vue"),
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/konsultasi",
			name: "Konsultasi",
			component: Konsultasi,
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/camera",
			name: "camera",
			component: Camera,
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/profile",
			name: "Profile",
			component: Profile,
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/produk",
			name: "Produk",
			component: Product,
		},
		{
			path: "/welcome",
			name: "Welcome",
			component: () => import("../views/Welcome.vue"),
			beforeEnter: ifNotAuthenticated,
		},
		{
			path: "/other",
			name: "Other",
			component: () => import("../views/Other.vue"),
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/selfie",
			name: "selfie",
			component: () => import("../views/Selfie.vue"),
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/riwayat",
			name: "riwayat",
			component: History,
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/riwayat/:id",
			component: Detailriwayat,
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/invoice",
			name: "invoice",
			component: Invoice,
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/invoice/:id",
			component: Detailinvoice,
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/online-shop",
			name: "OnlineShop",
			component: () => import("../views/OnlineShop.vue"),
		},
		{
			path: "/online-shop/:id",
			name: "OnlineShopBuy",
			component: () => import("../views/OnlineShopBuy.vue"),
		},
		{
			path: "/chat",
			name: "Chat",
			component: () => import("../views/Chat.vue"),
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/produk/:id",
			component: DetailProduct,
		},
		{
			path: "/edit-profile",
			name: "Editprofile",
			component: Editprofile,
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/forgot-password",
			name: "ForgotPassword",
			component: ForgotPassword,
		},
		{
			path: "/contact",
			name: "Contact",
			component: Contact,
		},
		{
			path: "*",
			component: PageNotFound,
		},
	],
});
export default router;
