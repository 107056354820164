import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import titleMixin from "./mixins/titleMixin";
import VueMeta from "vue-meta";
import OTPInput from "@8bu/vue-otp-input";
import Vuelidate from "vuelidate";
import GoogleAuth from "./config/google_oAuth.js";
const gauthOption = {
	clientId:
		"880951742368-p0cconia4oinp4i1nubuu6nmh8eu37t9.apps.googleusercontent.com",
	scope: "profile email",
	prompt: "select_account",
};
Vue.use(GoogleAuth, gauthOption);
Vue.use(Vuelidate);
Vue.use(OTPInput);
Vue.use(VueMeta);

Vue.mixin(titleMixin);
Vue.config.productionTip = false;

new Vue({
	router: router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
