<template>
	<div id="detail-product">
		<v-app-bar class="subtitle-bar white elevation-0">
			<v-row style="">
				<v-col cols="3" class="py-4 px-0">
					<button
						type="button"
						@click="hasHistory() ? $router.go(-1) : $router.push('/')"
						class="btn btn-outline-success"
					>
						<v-icon>mdi-chevron-left</v-icon> Back
					</button>
				</v-col>
				<v-col class="d-flex justify-space-around">
					<v-toolbar-title>Produk</v-toolbar-title>
				</v-col>
				<v-col cols="2" class="d-flex justify-end"> </v-col>
			</v-row>
		</v-app-bar>
		<v-container flud class="ma-0 pa-0">
			<div class="image-detail-product">
				<img
					v-if="produk && produk.image"
					:src="produk.image"
					class="black--text align-end"
					gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
					height="367px"
					width="367px"
				/>
			</div>
		</v-container>
		<v-container class="px-5">
			<v-card
				class="elevation-2 product-detail"
				outlined
				style="padding-bottom: 26px !important"
			>
				<h2 v-if="produk && produk.nama">{{ produk.nama }}</h2>
				<p
					class="text-letter label-category-name"
					v-if="produk && produk.kategori && produk.kategori.nama"
				>
					{{ produk.kategori.nama }}
				</p>
				<v-card-actions>
					<!-- <v-btn
            v-if="produk && produk.link_marketplace && produk.link_marketplace.market_place_1"
            @click="buyProduct(produk.link_marketplace.market_place_1)"
            block
            color="white--text"
            class="btn-beli-produk"
            style="box-shadow: 0 4px rgb(60 50 48/18%) !important"
          >
            Beli produk ini
          </v-btn> -->
					<v-dialog persistent max-width="400">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								block
								color="white--text"
								class="btn-beli-produk"
								style="box-shadow: 0 4px rgb(60 50 48/18%) !important"
							>
								Beli Produk ini
							</v-btn>
						</template>
						<v-container>
							<v-card class="box-dialog" style="box-shadow: none">
								<v-row class="box-dialog-row">
									<v-list-item v-bind:to="'/online-shop/'">
										<v-col cols="12">
											<div class="box-background">
												<h3>Online Shop</h3>
											</div>
										</v-col>
									</v-list-item>
									<v-list-item
										v-bind:href="'https://wa.me/6285643302133?text=halo'"
									>
										<v-col cols="12">
											<div class="box-background">
												<h3>Online Shop</h3>
											</div>
										</v-col>
									</v-list-item>
								</v-row>
							</v-card>
						</v-container>
					</v-dialog>
				</v-card-actions>
				<v-list-item-subtitle>
					<div
						v-if="produk && produk.deskripsi"
						v-html="produk.deskripsi"
					></div>
					<div class="produk-info">
						<p v-if="produk && produk.bpom">BPOM : {{ produk.bpom }}</p>
						<p v-if="produk && produk.netto">NETTO :{{ produk.netto }}</p>
					</div>
					<label for="" class="font-weight-bold">HARGA : </label>
					<p v-if="produk && produk.harga" class="harga">
						<small class="text-decoration-line-through">
							Rp.{{ produk.harga.toLocaleString() }}
						</small>
						<br />
						<span class="font-weight-black"
							>Rp. {{ (produk.harga - produk.discount).toLocaleString() }}</span
						>
					</p>
					<label for="" class="font-weight-bold">MANFAAT PEMAKAIAN : </label>
					<p>{{ produk.manfaat_pemakaian }}</p>
					<label for="" class="font-weight-bold">CARA PENGGUNAAN : </label>
					<p>{{ produk.cara_penggunaan }}</p>
					<label for="" class="font-weight-bold">KOMPOSISI : </label>
					<p v-if="produk && produk.ingredients">
						{{ produk.ingredients }}
					</p>
					<label for="" class="font-weight-bold">DISCLAIMER : </label>
					<p>{{ produk.disclaimer }}</p>
				</v-list-item-subtitle>
			</v-card>
		</v-container>
	</div>
</template>

<script>
	import { GetDetailProduct } from "../../services/api";
	import helpers from "../../helpers/auth";

	import "./detail-product.component.scss";

	export default {
		name: "DetailProduct",
		data() {
			return {
				produk: {},
				id: this.$route.params.id,
				items: [
					{
						title: "Online Shop",
						link: "/online-shop/",
					},
					{
						title: "Admin",
						link: "https://web.whatsapp.com/send?phone=6285643302133",
					},
				],
			};
		},

		methods: {
			// getProduk() {
			//   this.produk.forEach((e) => {
			//     if (e.slug == this.$route.params.slug) {
			//       this.produkDetail = e;
			//     }
			//   });
			hasHistory() {
				return window.history.length > 2;
			},
			getProduk() {
				console.log(this.id);
				GetDetailProduct(this.id)
					.then((res) => {
						this.produk = res.data.data;
						this.items[0].link = "/online-shop/" + this.produk.id;
					})
					.catch((err) => {
						console.log(err);
					});
			},
			buyProduct(link) {
				console.log(link);
				window.location.href = link;
			},
		},
		computed: {
			userData() {
				return helpers.getData();
			},
		},
		mounted() {},

		created() {
			this.getProduk();
		},
	};
</script>
