<template>
  <div class="welcome">
    <v-container>
      <v-row class="text-center">
        <button
          type="button"
          @click="hasHistory() ? $router.go(-1) : $router.push('/')"
          class="my-5 btn btn-outline-success btn-back"
        >
          <v-icon>mdi-chevron-left</v-icon> Back
        </button>
      </v-row>
    </v-container>
    <div class="logo-welcome">
      <v-img
        class="logo-er-1"
        src="../assets/asset/logo-white@2x.png"
        lazy-src="../assets/asset/logo-white.png"
        max-width="176px"
        max-height="84px"
      >
      </v-img>
      <v-container>
        <v-row class="text-center">
          <h2>Maaf Halaman Tidak tersedia</h2>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagenotfound",
  data: () => ({
    value: 1,
    drawer: null,
  }),
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>
    
<style>
@import "../assets/css/welcome.component.scss";
</style>