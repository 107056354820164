
const tokenKey = 'user-data'

const setData = (data) => {
    localStorage.setItem(tokenKey, JSON.stringify(data));
}

const getData = () => {
    const authData = localStorage.getItem(tokenKey);
    if (authData) {
        const data = JSON.parse(authData);
        return data
    }

    return null

}

const getToken = () => {
    const authData = localStorage.getItem(tokenKey)
    if (authData) {
        const data = JSON.parse(authData);
        console.log(data.token)
        return data;
    }
}

const ClearData = () => {
    localStorage.removeItem(tokenKey)
}
export default { setData, getData, ClearData, getToken }
