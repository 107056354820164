<template>
	<div id="detailRiwayat">
		<v-app-bar class="subtitle-bar white elevation-0">
			<v-row style="">
				<v-col cols="3" class="py-4 px-0">
					<button
						type="button"
						@click="hasHistory() ? $router.go(-1) : $router.push('/')"
						class="btn btn-outline-success"
					>
						<v-icon>mdi-chevron-left</v-icon> Back
					</button>
				</v-col>
				<v-col class="d-flex justify-space-around">
					<v-toolbar-title>Summary</v-toolbar-title>
				</v-col>
				<v-col cols="2" class="d-flex justify-end"> </v-col>
			</v-row>
		</v-app-bar>
		<v-container
			v-show="Object.keys(riwayat).length === 0"
			class="text-center text--secondary mx-auto pt-5"
			fill-height
		>
			<v-row align="center" justify="center" class="ma-2">
				<div class="p-5">
					<v-img
						src="../assets/diagnosa@2x.png"
						width="180"
						class="ma-auto"
					></v-img>
				</div>
				<p class="headline font-weight-bold mt-4 mb-4">
					Detail Riwayat diagnosa kosong
				</p>
				<p class="mb-5">
					Anda belum mempunyai detail riwayat diagnosa. Silahkan memulai
					konsultasi dengan dokter kecantikan kami untuk memperoleh detail
					riwayat diagnosa baru
				</p>
				<v-btn
					@click="$router.push('konsultasi')"
					block
					color="white--text"
					class="btn-beli-produk"
					style="box-shadow: 0 4px rgb(60 50 48/18%) !important"
				>
					Konsultasi Sekarang
				</v-btn>
			</v-row>
		</v-container>
		<v-container v-if="Object.keys(riwayat).length !== 0">
			<v-col :cols="12">
				<v-card
					class="mx-auto mb-2 elevation-2 pa-4"
					outlined
					style="padding-bottom: 26px !important"
				>
					<div class="d-flex justify-space-between align-end">
						<p class="font-weight-bold title text-name">
							<!-- {{ riwayat.pasien.nama }} -->
						</p>
						<p class="float-right">{{ riwayat.created_at | moment }}</p>
					</div>
					<v-list-item three-line class="list-foto">
						<v-list-item-avatar
							tile
							width="100"
							height="115"
							color="grey"
							v-for="(img, i) in riwayat.konsultasi_image"
							:key="i"
						>
							<v-img :src="img.gambar"></v-img>
						</v-list-item-avatar>
						<!-- <v-list-item-avatar tile width="100" height="115" color="grey">
              <v-img src="../assets/Renee-M.jpg"></v-img>
            </v-list-item-avatar>
            <v-list-item-avatar tile width="100" height="115" color="grey">
              <v-img src="../assets/Renee-M.jpg"></v-img>
            </v-list-item-avatar> -->
					</v-list-item>
					<v-list-item-subtitle>
						<label for="" class="font-weight-bold">Tanggal lahir :</label>
						<p v-if="riwayat.pasien.tanggal_lahir">
							{{ riwayat.pasien.tanggal_lahir | moment }}
						</p>
						<label for="" class="font-weight-bold">Jenis kelamin :</label>
						<p>{{ riwayat.pasien.gender }}</p>
						<label for="" class="font-weight-bold">Kondisi sebelumnya :</label>
						<ul v-for="kondisi in riwayat.kondisi_sebelumnya" :key="kondisi.id">
							<li>
								<p @click="$router.push(`/riwayat/${kondisi.id}`)">
									Working diagnosis {{ kondisi.date | momentslash }}
									<a>[Detail]</a>
								</p>
							</li>
						</ul>
					</v-list-item-subtitle>
					<v-divider></v-divider>
					<v-list-item-subtitle>
						<label for="" class="font-weight-bold gold"
							>Kondisi saat ini ({{ riwayat.created_at | moment }}) :</label
						>
					</v-list-item-subtitle>
					<v-list-item-subtitle>
						<div class="d-flex justify-space-between">
							<div>
								<label for="" class="font-weight-bold">Jenis kulit :</label>
								<p>{{ riwayat.jenis_kulit }}</p>
							</div>
							<div class="pr-10">
								<label for="" class="font-weight-bold">Kulit sensitif :</label>
								<p>{{ riwayat.kulit_sensitif }}</p>
							</div>
						</div>
						<div v-for="(keluhan, k) in riwayat.konsultasi_meta" :key="k">
							<label for="" class="font-weight-bold">{{
								k === 1
									? "Keluhan Kedua : "
									: k === 2
									? "Keluhan Ketiga :"
									: k === 3
									? "Keluhan Keempat :"
									: k === 4
									? "Keluhan Kelima :"
									: "Keluhan Pertama :"
							}}</label>
							<p>{{ keluhan.complaint }} pada {{ keluhan.face_area }}</p>
						</div>
						<label
							v-show="riwayat.informasi_lainnya"
							for=""
							class="font-weight-bold"
							>Informasi tambahan :</label
						>
						<p>{{ riwayat.informasi_lainnya }}</p>
					</v-list-item-subtitle>
					<v-divider></v-divider>
					<v-list-item-subtitle>
						<label for="" class="font-weight-bold">Working diagnosis :</label>
						<p>{{ riwayat.working_diagnosis }}</p>
						<label for="" class="font-weight-bold">Treatment:</label>
						<p>{{ riwayat.treatment }}</p>
						<label for="" class="font-weight-bold"
							>Resep ({{ riwayat.resep.kode_resep }}):</label
						>
						<ol
							v-for="produk in riwayat.resep.produk_relation"
							:key="produk.nama"
						>
							<li>{{ produk.nama }}</li>
						</ol>
						<v-card
							class="mx-auto pa-2 my-4 elevation-4 inv-riwayat"
							max-width="400"
							outlined
							@click="$router.push(`/invoice/${riwayat.resep.id}`)"
							><div class="flex-box">
								<v-list-item-subtitle style="padding-left: 17px">
									<label for="" class="font-weight-bold"
										>({{ riwayat.resep.kode_resep }})</label
									>
									<p class="ma-0">{{ riwayat.created_at | moment }}</p>
								</v-list-item-subtitle>
								<v-icon>mdi-arrow-right</v-icon>
							</div>
						</v-card>
						<label for="" class="font-weight-bold">Cara pemakaian :</label>
						<ul>
							<li v-show="riwayat.resep.cara_pemakaian.pagi">
								<b>Pagi : </b>{{ riwayat.resep.cara_pemakaian.pagi }}
							</li>
							<li v-show="riwayat.resep.cara_pemakaian.malam">
								<b>Malam : </b>{{ riwayat.resep.cara_pemakaian.malam }}
							</li>
							<li v-show="riwayat.resep.cara_pemakaian.lainnya">
								<b>Lainnya : </b>{{ riwayat.resep.cara_pemakaian.lainnya }}
							</li>
						</ul>
						<p></p>
						<label for="" class="font-weight-bold"
							>Konsultasi dilakukan oleh:</label
						>
						<p>{{ riwayat.doctor }}</p>
					</v-list-item-subtitle>
					<v-card-actions>
						<v-dialog persistent max-width="400" style="box-shadow: none">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-bind="attrs"
									v-on="on"
									block
									color="white--text"
									class="btn-detail"
									style="box-shadow: 0 4px rgb(60 50 48/18%) !important"
								>
									Beli resep
								</v-btn>
							</template>
							<v-container>
								<v-card class="box-dialog">
									<v-row class="box-dialog-row">
										<v-list-item v-bind:to="'/online-shop/'">
											<v-col cols="12">
												<div class="box-background">
													<h3>Online Shop</h3>
												</div>
											</v-col>
										</v-list-item>
										<v-list-item
											v-bind:href="'https://wa.me/6285643302133?text=halo'"
										>
											<v-col cols="12">
												<div class="box-background">
													<h3>Online Shop</h3>
												</div>
											</v-col>
										</v-list-item>
									</v-row>
								</v-card>
							</v-container>
						</v-dialog>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-container>
		<BottomNav />
	</div>
</template>

<script>
	import BottomNav from "../components/BottomNav.vue";
	import { GetKonsultasiDetail } from "../services/api";
	import helpers from "../helpers/auth";
	import moment from "moment";

	export default {
		name: "DetailRiwayat",
		components: { BottomNav },
		data() {
			return {
				id: this.$route.params.id,
				riwayat: {},
				items: [
					{
						title: "Online Shop",
						link: "/online-shop/",
					},
					{
						title: "Admin",
						link: "https://web.whatsapp.com/send?phone=6285643302133",
					},
				],
			};
		},
		computed: {
			userData() {
				return helpers.getData();
			},
		},
		filters: {
			moment(date) {
				return moment(date)
					.locale("id")
					.format("D MMMM YYYY");
			},
			momentslash(date) {
				return moment(date)
					.local("id")
					.format("DD/MM/YYYY");
			},
		},
		mounted() {
			this.getKonsultasiDetail(this.userData.token, this.id);
		},
		methods: {
			// getRiwayat() {
			//   this.riwayat.forEach((e) => {
			//     if (e.slug == this.$route.params.slug) {
			//       this.riwayatDetail = e;
			//     }
			//   });
			hasHistory() {
				return window.history.length > 2;
			},
			getKonsultasiDetail(token, id) {
				GetKonsultasiDetail(token, id)
					.then((res) => {
						console.log(res);
						this.riwayat = res.data.data;
						console.log(this.riwayat);
					})
					.catch((err) => {
						console.log(err);
					});
			},
		},

		created() {
			// this.getRiwayat();
		},
	};
</script>

<style>
	div#detailRiwayat {
		background: #f3f3f3;
	}
	p.text-name {
		color: #bb8a1a;
	}
	.v-list-item__subtitle ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: 20px;
	}
	.v-list-item__subtitle ul li a {
		color: #a97e3d;
	}
	.list-foto .v-avatar,
	.list-foto .v-avatar .v-image {
		border-radius: 10px !important;
	}
	.list-foto {
		margin-top: -15px;
		padding: 0;
	}
	.gold {
		color: #bb8a1a;
	}
	.v-list-item__subtitle p {
		white-space: break-spaces;
	}
	#detailRiwayat .v-list-item__subtitle {
		white-space: break-spaces;
		padding-top: 15px;
	}
	label.font-weight-bold.gold {
		color: #a97e3d;
	}
	.v-card__actions {
		padding: 0;
	}
	.inv-riwayat .v-icon {
		float: right;
		padding: 12px 0;
	}

	.inv-riwayat .v-list-item__subtitle {
		display: inline-block;
		padding-top: 0 !important;
	}
	.v-toolbar__content {
		background: #ffff;
	}
	#detailRiwayat p {
		font-size: 18px;
	}
	#detailRiwayat label {
		font-size: 18px;
	}
	.v-list-item__subtitle ul li p {
		margin-bottom: 0;
	}
	.v-list-item__subtitle ol li {
		font-size: 18px;
	}
	.v-list-item__subtitle label {
		color: #000000;
	}
	#detailRiwayat .flex-box {
		display: flex;
		align-items: center;
		height: 74px;
	}
</style>
