<template>
	<div class="konsultasi">
		<v-app-bar>
			<button
				type="button"
				@click="hasHistory() ? $router.go(-1) : $router.push('/')"
				class="my-5 btn btn-outline-success"
			>
				<v-icon>mdi-chevron-left</v-icon> Back
			</button>
			<v-toolbar-title class="text-center">Isi data anda</v-toolbar-title>
		</v-app-bar>
		<v-container>
			<div class="wrapper-konsultasi">
				<!-- <v-checkbox
          v-model="check_blurmata"
          :label="`Blur area mata untuk menjaga privasi foto saya`"
        ></v-checkbox> -->
				<div class="wrap-img-upload">
					<div class="preview">
						<div v-for="(image, key) in photos" :key="key">
							<img :src="image.src" />
						</div>
					</div>
					<input
						id="imgUpload"
						type="file"
						@change="uploadImage"
						multiple
						hidden
					/>
					<button @click="chooseFiles()" class="btn-img-upload">
						<v-icon>mdi-plus</v-icon> Tambah foto bagian wajah yang memiliki
						keluhan
					</button>
				</div>
				<div v-if="photos.length >= 2">
					<h2 class="title font-weight-bold">
						Mohon ceritakan keluhan yang kamu alami:
					</h2>
					<validation-observer ref="observer">
						<v-checkbox
							class="check-observer"
							v-model="check_kondisi_sama"
							:label="
								`Kondisi saya sama seperti sewaktu konsultasi terakhir saya`
							"
						></v-checkbox>
						<!-- {{check_kondisi_sama}} -->
						<form>
							<div
								class="form-group"
								v-for="(konsultasi_field, k) in new_input"
								:key="k"
							>
								<v-row v-for="(field, l) in konsultasi_field" :key="l">
									<v-col class="" cols="12">
										<label for="">{{
											k === 0 && l === 2
												? "Keluhan pertama"
												: k === 0 && l === 3
												? "Area pada wajah pertama"
												: k === 1 && l === 0
												? "Keluhan kedua"
												: k === 1 && l === 1
												? "Area pada wajah kedua"
												: k === 2 && l === 0
												? "Keluhan ketiga"
												: k === 2 && l === 1
												? "Area pada wajah ketiga"
												: k === 3 && l === 0
												? "Keluhan keempat"
												: k === 3 && l === 1
												? "Area pada wajah keempat"
												: k === 4 && l === 0
												? "Keluhan kelima"
												: k === 4 && l === 1
												? "Area pada wajah kelima"
												: field.title
										}}</label>
										<validation-provider
											v-slot="{ errors }"
											name="Jenis Kulit"
											class="grey-select"
											rules="required"
											v-if="field.title === 'Jenis kulit'"
										>
											<v-select
												:items="field.options"
												:error-messages="errors"
												data-vv-name="Jenis Kulit"
												required
												label="Pilih"
												solo
												item-text="key"
												item-value="value"
												v-model="kulit.jenis_kulit"
											></v-select>
										</validation-provider>

										<validation-provider
											v-slot="{ errors }"
											name="Kulit Sensitif"
											class="grey-select"
											rules="required"
											v-if="field.title === 'Kulit sensitif'"
										>
											<v-select
												:items="field.options"
												:error-messages="errors"
												required
												label="Pilih"
												solo
												item-text="key"
												item-value="value"
												v-model="kulit.kulit_sensitif"
											></v-select>
										</validation-provider>
										<div>
											<validation-provider
												v-slot="{ errors }"
												name="Keluhan"
												class="grey-select"
												:rules="k < 1 ? 'required' : ''"
												v-if="field.title === 'Keluhan'"
											>
												<v-select
													:items="field.options"
													v-model="konsultasi_data[k].keluhan"
													:error-messages="errors"
													data-vv-name="first_complaint"
													label="Pilih"
													solo
													item-text="key"
													item-value="value"
													outlined
												></v-select>
											</validation-provider>

											<validation-provider
												v-slot="{ errors }"
												class="grey-select"
												name="Area wajah"
												:rules="k < 1 ? 'required' : ''"
												v-if="field.title === 'Area pada wajah'"
											>
												<v-select
													:items="field.options"
													v-model="konsultasi_data[k].area_wajah"
													:error-messages="errors"
													data-vv-name="area_wajah"
													label="Pilih"
													solo
													item-text="key"
													item-value="value"
													outlined
												></v-select>
											</validation-provider>
										</div>
									</v-col>
								</v-row>
							</div>
							<div class="form-group">
								<v-row>
									<v-col>
										<v-btn
											block
											x-large
											outlined
											:disabled="new_input.length >= 5"
											class="btn-outline-dashed text-capitalize"
											@click="add(k)"
											>+ Tambah keluhan</v-btn
										>
									</v-col>
								</v-row>
							</div>
							<div class="form-group">
								<v-row>
									<v-col class="" cols="12">
										<label for="">Informasi lainnya</label>
										<validation-provider
											v-slot="{ errors }"
											name="optional"
											class="grey-select"
											rules="min:10"
										>
											<v-textarea
												v-model="informasi_lainnya"
												solo
												name="input-7-4"
												label="Optional bilamana ada"
												:error-messages="errors"
											></v-textarea>
										</validation-provider>
									</v-col>
								</v-row>
							</div>
							<v-alert type="error" v-show="error_res !== null" dismissible>{{
								error_res
							}}</v-alert>
							<v-btn
								class="mr-4 text-capitalize box-shadow-bottom btn-brown"
								block
								dark
								style="
                  text-transform: unset !important;
                  box-shadow: 0 4px rgb(60 50 48/18%) !important;
                "
								@click="submit"
							>
								Konsultasi Sekarang
								<div class="text-right">
									<v-progress-circular
										v-show="loading === true"
										:width="3"
										color="yellow"
										indeterminate
									></v-progress-circular>
								</div>
							</v-btn>
						</form>
					</validation-observer>
				</div>
			</div>
		</v-container>
		<Loader :dialog="isLoading" :message="'Sending Data...'" />
		<SnackBar :show="showSnack" :text="'Data sent sucessfully'" />
		<BottomNav />
	</div>
</template>

<script>
	import { required, min } from "vee-validate/dist/rules";
	import helpers from "../../helpers/auth";
	import {
		extend,
		ValidationObserver,
		ValidationProvider,
		setInteractionMode,
	} from "vee-validate";
	import BottomNav from "../../components/BottomNav.vue";
	import "./konsultasi.view.scss";
	import {
		GetKonsultasi,
		PostKonsultasi,
		GetKonsultasiLatest,
	} from "../../services/api";
	import Loader from "../../components/Loader";
	import SnackBar from "../../components/SnackBar";

	setInteractionMode("eager");

	extend("required", {
		...required,
		message: "{_field_} tidak boleh kosong",
	});

	extend("min", {
		...min,
		message: "{_field_} harus lebih dari 10 karakter",
	});

	export default {
		name: "Konsultasi",
		components: {
			BottomNav,
			ValidationProvider,
			ValidationObserver,
			Loader,
			SnackBar,
		},
		data() {
			return {
				images: [],
				photos: [],
				photosFile: [],
				second_input: [],
				isLoading: false,
				showSnack: false,
				kulit: {
					jenis_kulit: "",
					kulit_sensitif: "",
				},
				konsultasi_field: {
					keluhan: "",
					area_wajah: "",
				},
				konsultasi_data: [
					{
						keluhan: "",
						area_wajah: "",
					},
					{
						keluhan: "",
						area_wajah: "",
					},
					{
						keluhan: "",
						area_wajah: "",
					},
					{
						keluhan: "",
						area_wajah: "",
					},
					{
						keluhan: "",
						area_wajah: "",
					},
				],
				attach: false,
				selectedFile: "",
				check_kondisi_sama: false,
				datakeluhan: null,
				errors: null,
				new_input: [],
				informasi_lainnya: "",
				inputs: [
					{
						skin_type: ["Normal", "Berminyak", "Kering"],
						skin_sensitive: ["Ya", "Tidak"],
						first_complaint: [
							"Jerawat",
							"Beruntusan",
							"Bekas Jerawat",
							"Wajah Kusam",
							"Flek Hitam",
							"Pori-pori Besar",
							"Komedo Hitam",
							"Komedo Putih",
							"Penuaan (kerutan)",
							"Penuaan (kulit tidak kencang)",
							"Lainya",
						],
						area_wajah: [
							"Seluruh Wajah",
							"Pipi sebelah kanan",
							"Pipi sebelah kiri",
							"Dagu",
							"Dahi",
							"Hidung",
							"Area Mata",
							"Area Bibir",
							"T-zone",
						],
						area: "",
						optional: "",
					},
				],
				field_input: null,
				temp_input: null,
				error_res: null,
				loading: Boolean,
				permissions: null,
			};
		},
		computed: {
			userData() {
				return helpers.getData();
			},
		},
		watch: {
			check_kondisi_sama: function() {
				if (this.check_kondisi_sama == true) {
					this.getKonsultasiLatest();
				}
			},
		},
		mounted() {
			console.log("mounted");
			console.log(window.cordova);
			var permissions = window.cordova.plugins.permissions;
			permissions.requestPermission(
				permissions.CAMERA,
				this.getSuccess,
				this.getError
			);

			this.getKonsultasi();
			if (this.$route.params.photos) {
				this.photos = this.$route.params.photos;
				console.log(this.photos.length);
			}
			this.getKonsultasiLatest();
		},
		methods: {
			getError() {
				console.log("Camera permission is not turned on");
			},

			getSuccess(status) {
				if (!status.hasPermission) this.getError();
			},
			add() {
				console.log(this.temp_input);
				console.log(this.kulit);
				this.new_input = [...this.new_input, this.second_input];
				console.log(this.new_input);

				for (let i in this.temp_input) {
					this.field_input = [...this.field_input, this.temp_input[i]];
				}
				console.log(this.field_input);
				console.log(this.konsultasi_data);
			},
			getKonsultasi() {
				GetKonsultasi(this.userData.token)
					.then((res) => {
						console.log(res.data.data);
						this.temp_input = res.data.data;
						this.field_input = res.data.data;
						this.new_input.push(res.data.data);
						this.second_input = [
							...this.second_input,
							res.data.data[2],
							res.data.data[3],
						];
						console.log(this.field_input);
					})
					.catch((err) => {
						console.log(err);
					});
			},
			getKonsultasiLatest() {
				GetKonsultasiLatest(this.userData.token)
					.then((response) => {
						if (this.check_kondisi_sama == true) {
							console.log(response.data);
							let res = response.data;
							this.kulit.jenis_kulit = res.data.jenis_kulit;
							this.kulit.kulit_sensitif = res.data.kulit_sensitif;
							// console.log(res.data.konsultasi_meta);
							// this.field_input = res.data.konsultasi_meta;
							// this.konsultasi_data = res.data.konsultasi_meta;
							let i = res.data.konsultasi_meta.length;
							var a;
							for (a = 0; a < i - 1; a++) {
								this.new_input = [...this.new_input, this.second_input];
							}
							console.log(this.new_input);
							for (i in res.data.konsultasi_meta) {
								for (let index in this.temp_input) {
									this.field_input = [
										...this.field_input,
										this.temp_input[index],
									];
								}
								this.konsultasi_data[i].keluhan =
									res.data.konsultasi_meta[i].complaint;
								this.konsultasi_data[i].area_wajah =
									res.data.konsultasi_meta[i].face_area;
							}

							console.log(this.konsultasi_data);
							this.informasi_lainnya = res.data.informasi_lainnya;
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},

			remove(index) {
				this.inputs.splice(index, 1);
			},
			dataURLtoFile(dataurl, filename) {
				var arr = dataurl.split(","),
					mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]),
					n = bstr.length,
					u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new File([u8arr], filename, { type: mime });
			},
			submit() {
				this.isLoading = true;
				this.photosFile = [...this.photos];
				for (let i in this.photosFile) {
					this.photosFile[i].file = this.dataURLtoFile(
						this.photosFile[i].src,
						`${this.photosFile[i].id}.png`
					);
				}
				console.log(this.photosFile);
				this.$refs.observer.validate();
				let formData = new FormData();
				for (let i in this.photos) {
					formData.append("photos", this.photos[i].file);
				}
				formData.append("jenis_kulit", this.kulit.jenis_kulit);
				formData.append("kulit_sensitif", this.kulit.kulit_sensitif);
				for (let i in this.konsultasi_data) {
					if (
						this.konsultasi_data[i].keluhan !== "" &&
						this.konsultasi_data[i].area_wajah !== ""
					) {
						formData.append("keluhan", this.konsultasi_data[i].keluhan);
						formData.append("area_wajah", this.konsultasi_data[i].area_wajah);
					}
				}
				formData.append("informasi_lainnya", this.informasi_lainnya);
				PostKonsultasi(this.userData.token, formData)
					.then((res) => {
						this.isLoading = false;
						this.showSnack = true;
						setTimeout(() => {
							this.$router.push("/chat");
						}, 3000);
						console.log(res);
						console.log(res.message);
					})
					.catch((err) => {
						this.isLoading = false;
						console.log(err);
						console.log(err.response.data.message);
						this.error_res = err.response.data.message;
					});
			},
			hasHistory() {
				return window.history.length > 2;
			},
			chooseFiles: function() {
				// document.getElementById("imgUpload").click();
				this.$router.push({
					name: "camera",
					params: {
						from: "Konsultasi",
						photos: this.photos,
					},
				});
			},
			uploadImage(e) {
				var selectedFiles = e.target.files;
				for (let i = 0; i < selectedFiles.length; i++) {
					console.log(selectedFiles[i]);
					this.images.push(selectedFiles[i]);
				}

				for (let i = 0; i < this.images.length; i++) {
					let reader = new FileReader();
					reader.onload = () => {
						this.$refs.image[i].src = reader.result;

						console.log(this.$refs.image[i].src);
					};

					reader.readAsDataURL(this.images[i]);
				}
			},
		},
	};
</script>
<style>
	.preview img {
		padding-right: 5px;
	}
	.preview {
		max-width: 100%;
		overflow: auto;
	}
</style>
