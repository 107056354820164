<template>
  <div id="nav">
    <v-bottom-navigation
      app
      :value="value"
      color="amber"
      grow
      height="60px"
      class="sticky-nav"
    >
      <v-btn value="Home" :to="{ path: '/' }" height="60px" :class="{'active':$route.name.startsWith('Home')}">
        <span>Home</span>
        <span class="icon-home"></span>
      </v-btn>

      <v-btn
        value="Profil"
        :to="{ path: '/profile' }"
        class="p-4"
        height="60px"
        style="height: 60px !important"
        :class="{'active':$route.name.startsWith('Profile')}"
      >
        <span class="p-4">Profil</span>
        <span class="icon-profil"></span>
      </v-btn>

      <v-btn value="other" :to="{ path: '/other' }" height="60px" :class="{'active':$route.name.startsWith('Other')}">
        <span>Lainnya</span>
        <span class="icon-other"></span>
      </v-btn>
    </v-bottom-navigation>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-container>
        <v-list-item>
          <v-list-item-avatar>
            <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
          </v-list-item-avatar>

          <v-list-item-content
            >Hello,
            <v-list-item-title class="font-wwweight-bold">
              Juan A Kartawidjaya</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.link"
            @click="itemClick(item.title)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import helpers from "../helpers/auth";

export default {
  name: "BottomNav",
  data: () => ({
    value: 1,
    drawer: null,
    items: [
      { title: "Edit Profil", icon: "mdi-account", link: "/profile/" },
      { title: "Resep digital", icon: "mdi-flask-outline", link: "/invoice/" },
      { title: "Logout", icon: "mdi-exit-to-app", link: "/" },
    ],
  }),
  methods: {
    itemClick(title) {
      if (title == "Logout") {
        helpers.ClearData();
      }
    },
  },
};
</script>
