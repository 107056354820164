<template>
  <div class="text-center">

    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="orange accent-2" dark>
        <v-card-text>
          {{message}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "Loader",
   props: ["message","dialog"]
};
</script>