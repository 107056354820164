<template>
  <div class="list-invoice">
    <v-app-bar v-show="dataResep.length > 1">
      <button
        type="button"
        @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        class="my-5 btn btn-outline-success"
      >
        <v-icon>mdi-chevron-left</v-icon> Back
      </button>
    </v-app-bar>
    <div class="card-group mx-2">
      <v-card
        class="mx-auto mb-2 elevation-4 invoice-box"
        max-width="400"
        max-height="82"
        height="82"
        outlined
        @click="$router.push('/invoice/' + invoice.id)"
        v-for="invoice in dataResep"
        :key="invoice.id"
      >
        <v-list-item-subtitle>
          <div class="flex-box">
            <label for="" class="font-weight-bold">{{
              invoice.kode_resep
            }}</label>
            <p>{{ invoice.created_at | moment }}</p>
          </div>
        </v-list-item-subtitle>
      </v-card>
      <v-container
        v-show="dataResep.length < 1"
        class="text-center text--secondary mx-auto pt-5"
      >
          <div class="p-5">
            <v-img
              src="../../assets/resep@2x.png"
              width="180"
              class="ma-auto d-block"
              block
            ></v-img>
          </div>
          <p class="headline font-weight-bold mt-4 mb-4 d-block">
            Resep kosong
          </p>
          <p class="mb-5">
            Anda belum mempunyai resep. Silahkan memulai konsultasi
            dengan dokter kecantikan kami atau hubungi admin kami untuk memperoleh resep baru
          </p>
          <v-btn
            @click="$router.push('/konsultasi')"
            block
            color="white--text"
            class="btn-beli-produk"
            style="box-shadow: 0 4px rgb(60 50 48/18%) !important"
          >
            Konsultasi Sekarang
          </v-btn>
      </v-container>
    </div>

    <BottomNav />
  </div>
</template>

<script>
import BottomNav from "../../components/BottomNav.vue";
import "./invoice.component.scss";
import { GetResep } from "../../services/api";
import helpers from "../../helpers/auth";
import moment from "moment";

export default {
  name: "invoice",
  components: {
    BottomNav,
  },
  data() {
    return {
      invoices: [
        {
          id: 1,
          inv: "INV-2123-12312",
          date: "24 Juli 2020",
        },
        {
          id: 2,
          inv: "INV-2123-12312",
          date: "24 Agustus 2020",
        },
        {
          id: 3,
          inv: "INV-2123-12312",
          date: "22 Agustus 2020",
        },
        {
          id: 4,
          inv: "INV-2123-12312",
          date: "10 Juni 2020",
        },
      ],
      dataResep: null,
    };
  },
  filters: {
    moment(date) {
      return moment(date).locale("id").format("D MMMM YYYY");
    },
  },
  computed: {
    userData() {
      return helpers.getData();
    },
  },
  mounted() {
    this.getResep();
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    getResep() {
      GetResep(this.userData.token)
        .then((res) => {
          if (res) {
            this.dataResep = res.data.data;
            console.log(this.dataResep);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.list-invoice {
  background: #f8f8f8;
  height: 100vh;
}
.list-invoice .card-group {
  padding-top: 20px;
}
.invoice-box {
  display: flex;
  align-items: center;
}
.list-invoice .v-list-item__subtitle {
  padding: 10px 26px !important;
  height: 100%;
  display: flex;
  align-items: center;
}
.list-invoice .v-list-item__subtitle p {
  white-space: break-spaces;
  margin-bottom: 0 !important;
  font-size: 18px;
  font-weight: 400;
}
.v-list-item__subtitle label {
  font-size: 18px;
}
.list-invoice .v-card {
  margin-bottom: 23px !important;
}
</style>
