<template>
	<div id="detail-invoice">
		<v-app-bar>
			<button
				type="button"
				@click="hasHistory() ? $router.go(-1) : $router.push('/')"
				class="my-5 btn btn-outline-success"
			>
				<v-icon>mdi-chevron-left</v-icon> Back
			</button>
		</v-app-bar>
		<v-container>
			<v-row>
				<v-col :cols="12">
					<v-card
						class="mx-auto mb-2 elevation-4"
						outlined
						style="margin-bottom: 19px !important"
					>
						<v-container class="py-0">
							<v-row class="box-invoice">
								<v-col cols="7">
									<p class="font-weight-bold ma-0 text-bold">
										{{ detailInvoice.kode_resep }}
									</p>
								</v-col>
								<v-col cols="5">
									<p class="float-right ma-0">
										{{ detailInvoice.created_at | moment }}
									</p>
								</v-col>
							</v-row>
							<v-row class="box-info">
								<v-col cols="8" class="pt-0">
									<p class="font-weight-bold ma-0 text-bold">
										Konsultasi dilakukan oleh:
									</p>
									<p class="mb-0">dr. Erna Purnamasari</p>
									<p class="mb-0">SIP : 283452378462</p>
								</v-col>
							</v-row>
						</v-container>
						<v-divider></v-divider>
						<v-simple-table>
							<template v-slot:default cellspacing="0" cellpadding="0">
								<tbody>
									<tr
										v-for="item in detailInvoice.produk_relation"
										:key="item.nama_produk"
									>
										<td class="total-item">{{ item.qty }}</td>
										<td class="font-weight-bold">
											<span class="text-16 text-dot">{{ item.nama }}</span>
										</td>
										<td class="price-product">
											<span class="text-16">Rp. {{ item.harga_produk }}</span>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
						<v-divider></v-divider>
						<v-container class="py-0">
							<v-row class="total-box">
								<v-col cols="6">
									<p class="font-weight-bold ma-0">Grand Total</p>
								</v-col>
								<v-col cols="6">
									<p class="float-right ma-0 text-15">
										{{ detailInvoice.grand_total }}
									</p></v-col
								>
							</v-row>
						</v-container>
					</v-card>
					<v-dialog
						v-model="dialog"
						persistent
						max-width="400"
						style="box-shadow: none"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								block
								color="white--text"
								class="btn-register"
								style="text-transform: none !important"
							>
								Beli resep
							</v-btn>
						</template>
						<v-container>
							<v-card class="box-dialog">
								<v-row class="box-dialog-row">
									<v-list-item v-bind:to="'/online-shop/'">
										<v-col cols="12">
											<div class="box-background">
												<h3>Online Shop</h3>
											</div>
										</v-col>
									</v-list-item>
									<v-list-item
										v-bind:href="'https://wa.me/6285643302133?text=halo'"
									>
										<v-col cols="12">
											<div class="box-background">
												<h3>Online Shop</h3>
											</div>
										</v-col>
									</v-list-item>
								</v-row>
							</v-card>
						</v-container>
					</v-dialog>
				</v-col>
			</v-row>
		</v-container>
		<BottomNav />
	</div>
</template>

<script>
	import BottomNav from "../../components/BottomNav.vue";
	import { GetResepDetail } from "../../services/api";
	import helpers from "../../helpers/auth";
	import "./detailinvoice.component.scss";
	import moment from "moment";

	export default {
		name: "DetailInvoice",
		components: { BottomNav },
		data() {
			return {
				id: this.$route.params.id,
				riwayat: {},
				detailInvoice: {},
				dialog: false,
				reseps: [
					{
						jumlah: 1,
						nama_produk: "Face Wash",
						harga: 75000,
					},
					{
						jumlah: 1,
						nama_produk: "Serum Anti Acne",
						harga: 75000,
					},
					{
						jumlah: 1,
						nama_produk: "Sunscreen Anti Acne",
						harga: 75000,
					},
				],
				items: [
					{
						title: "Online Shop",
						link: "/online-shop/",
					},
					{
						title: "Admin",
						link: "https://web.whatsapp.com/send?phone=6285643302133",
					},
				],
			};
		},
		mounted() {
			this.getDetailResep(this.userData.token, this.id);
		},
		filters: {
			moment(date) {
				return moment(date)
					.locale("id")
					.format("D MMMM YYYY");
			},
		},
		computed: {
			userData() {
				return helpers.getData();
			},
		},
		methods: {
			// getRiwayat() {
			//   this.riwayat.forEach((e) => {
			//     if (e.slug == this.$route.params.slug) {
			//       this.riwayatDetail = e;
			//     }
			//   });
			getDetailResep(token, id) {
				GetResepDetail(token, id)
					.then((res) => {
						this.detailInvoice = res.data.data;
						console.log(this.detailInvoice);
					})
					.catch((err) => {
						console.log(err);
					});
			},
			hasHistory() {
				return window.history.length > 2;
			},
		},
	};
</script>
