<template>
  <div class="other">
    <v-app-bar>
      <button
        type="button"
        @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        class="my-5 btn btn-outline-success"
      >
        <v-icon>mdi-chevron-left</v-icon> Back
      </button>
    </v-app-bar>
    <v-container>
      <h1 class="font-weight-bold">Hubungi Kami</h1>
      <v-card class="pa-6 mb-3 text-center mt-5">
        <a href="tel:+6282284072112">
          <span class="color-theme">Telp: +62 822 8407 2112</span>
        </a>
      </v-card>
      <v-card class="pa-6 text-center">
        <a href="mailto:info@drernaofficial.com">
          <span class="color-theme">Email:info@drernaofficial.com</span>
        </a>
      </v-card>
      <BottomNav />
    </v-container>
  </div>
</template>
<script>
import BottomNav from "../components/BottomNav.vue";
export default {
  name: "Contact",
  components: { BottomNav },
  data: () => ({}),
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>

<style scoped>
span.color-theme {
  color: #c2a272;
}
span.color-theme {
    color: #c2a272;
    font-size: 22px;
}
.other a {
    text-decoration: none;
}
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: 0px 2px 4px -1px rgb(93 89 89 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 4%) !important;
}
.v-sheet.v-card {
    box-shadow: none !important;
    border: 1px #c3c3c3 solid !important;
}
</style>
