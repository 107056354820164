<template>
  <div class="login">
    <v-container class="text-xs-center">
      <button
        type="button"
        @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        class="my-5 btn btn-outline-success btn-back"
      >
        <v-icon>mdi-chevron-left</v-icon> Back
      </button>
      <v-layout child-flex justify-center align-center wrap>
        <v-flex fill-height>
          <v-card flat>
            <v-card-title primary-title class="login-title justify-center">
              <h3 class="m-auto">Request Reset password</h3>
            </v-card-title>
            <v-form>
              <div
                v-if="submitted && !$v.forgot_data.no_hp.required"
                class="invalid-feedback"
              >
                Nomor HP harus diisi
              </div>
              <v-text-field
                solo
                placeholder="Nomor handphone"
                name="Username"
                v-model="forgot_data.no_hp"
                :class="{
                  'is-invalid': submitted && $v.forgot_data.no_hp.$error,
                }"
              ></v-text-field>
              <v-alert type="error" v-show="response !== null">
                {{ response }}</v-alert
              >
              <v-alert type="success" v-show="ressuccess !== null">
                {{ ressuccess }}</v-alert
              >
              <v-card-actions>
                <v-btn large block class="btn-login" @click="Forgot()"
                  >Reset Password</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { Forgot } from "../services/api";

export default {
  data: () => ({
    forgot_data: {
      no_hp: "",
    },
    slice_hp: "",
    submitted: false,
    response: null,
    ressuccess: null,
  }),
  validations: {
    forgot_data: {
      no_hp: { required },
    },
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    Forgot() {
      this.slice_hp = this.forgot_data.no_hp.slice(
        this.forgot_data.no_hp.length - 4
      );

      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        Forgot(this.forgot_data)
          .then((data) => {
            console.log(data);
            this.ressuccess = data.data.message;
            return;
            // this.$router.push({
            //   name: "login",
            //   params: {
            //     no_hp: this.forgot_data.no_hp,
            //   },
            // });
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status == 400) {
              if (err.response.data.message == "No HP Not Exists!") {
                console.log(err.response.data.message);
                this.response = err.response.data.message;
                return;
              }
            }
          });
      }
    },
  },
};
</script>
<style>
@import "../assets/css/register.component.scss";
</style>