<template>
	<div class="home">
		<v-container>
			<div class="custom-text" v-if="userData">
				<h2 class="font-weight-home">Selamat datang,</h2>
				<h1 class="font-weight-black">{{ userData.nama }}</h1>
			</div>
		</v-container>
		<v-skeleton-loader
			type="image"
			:loading="true"
			v-if="firstLoad"
		></v-skeleton-loader>
		<v-carousel
			hide-delimiters
			cycle
			height="200"
			hide-delimiter-background
			show-arrows-on-hover
			class="slider_home"
			v-show="!firstLoad"
		>
			<v-carousel-item
				v-for="(slider, i) in sliders"
				:key="i"
				:src="slider.value"
				:to="slider.link"
			>
			</v-carousel-item>
		</v-carousel>
		<v-container fluid>
			<v-skeleton-loader
				type="list-item-three-line"
				:loading="loading"
				v-if="firstLoad"
			>
			</v-skeleton-loader>
			<v-row dense v-show="!firstLoad">
				<v-col class="menu-home" cols="12">
					<v-carousel
						cycle
						hide-delimiter-background
						:show-arrows="false"
						height="95px"
					>
						<v-carousel-item
							v-for="(dokter, i) in dokter"
							:key="i"
							:to="dokter.page"
							:src="dokter.src"
						>
							<v-card-title
								>{{ dokter.title }}
								<div class="desc-home">
									<p>{{ dokter.name }}</p>
									<v-icon>mdi-arrow-right</v-icon>
								</div>
							</v-card-title>
							<p
								style="color: rgb(177 177 177); font-size: 11px; margin-left: 55px; margin-top:-5px"
							>
								Slide untuk mengganti dokter
							</p>
						</v-carousel-item>
					</v-carousel>
				</v-col>
				<v-col class="menu-home" cols="12">
					<v-card to="/riwayat">
						<v-img
							src="../assets/riwayat@2x.png"
							class="font-weight-medium align-end"
							height="95px"
						>
							<v-card-title
								>Riwayat Kondisi
								<div class="enter-line">
									<v-card-title> Kulitmu </v-card-title>
									<v-icon>mdi-arrow-right</v-icon>
								</div>
							</v-card-title>
						</v-img>
					</v-card>
				</v-col>
				<v-col class="menu-home" cols="12">
					<v-card to="/produk">
						<v-img
							src="../assets/produk@2x.png"
							class="font-weight-medium align-end"
							height="95px"
						>
							<v-card-title>Katalog Produk </v-card-title>
						</v-img>
					</v-card>
				</v-col>
				<v-col class="menu-home" cols="6">
					<v-card to="/online-shop">
						<v-img
							src="../assets/onlineshop@2x.png"
							class="font-weight-medium align-end"
							height="95px"
						>
							<v-card-title>Online Shop </v-card-title>
						</v-img>
					</v-card>
				</v-col>
				<v-col class="menu-home" cols="6">
					<v-card href="https://wa.me/6285643302133?text=hello">
						<v-img
							src="../assets/img_admin.png"
							class="font-weight-medium align-end"
							height="95px"
						>
							<v-card-title>Admin </v-card-title>
						</v-img>
					</v-card>
				</v-col>
			</v-row>
		</v-container>

		<BottomNav />
	</div>
</template>

<script>
	import BottomNav from "../components/BottomNav.vue";
	import helpers from "../helpers/auth";
	import { GetSlider } from "../services/api";
	export default {
		name: "Home",
		components: { BottomNav },
		data: () => ({
			dokter: [
				{
					title: "Konsultasi Sekarang",
					name: "dr. Erna Purnamasari",
					src: require("../assets/konsultasi@2x.png"),
					flex: 12,
					page: "/konsultasi",
				},
				{
					title: "Konsultasi Sekarang",
					name: "dr. Eringga",
					src: require("../assets/konsultasi@2x.png"),
					flex: 12,
					page: "/konsultasi",
				},
				{
					title: "Konsultasi Sekarang",
					name: "dr. Agsela",
					src: require("../assets/konsultasi@2x.png"),
					flex: 12,
					page: "/konsultasi",
				},
			],
			sliders: [],
			loading: true,
			firstLoad: true,
		}),
		computed: {
			userData() {
				return helpers.getData();
			},
		},
		mounted() {
			GetSlider()
				.then((res) => {
					this.sliders = res.data.data;
					setTimeout(() => {
						if (this.firstLoad) this.firstLoad = false;
						this.loading = false;
					}, 1000);
				})
				.catch((err) => {
					console.log(err);
				});
		},
	};
</script>
<style>
	@import "../assets/css/home.component.scss";
</style>
