import { url } from "./client";

const Register = (params) => {
    return url.post("/auth/signup", params);
}

const Login = (params) => {
    return url.post("/auth/login", params);
}
const LoginGoogle = (params) => {
    return url.post("/auth/google", params);
}
const LoginFB = (params) => {
    return url.post("/auth/facebook", params);
}
const VerifikasiOtp = (params) => {
    return url.post("/auth/resend-otp", params);
}

const ResendOtp = (params) => {
    return url.post("/auth/resend-otp", params);
}

const Forgot = (params) => {
    return url.post("/auth/request-reset-password", params);
}

const GetProduct = () => {

    return url.get('/produk')
}

const GetSlider = () => {

    return url.get('/slider/home')
}
const GetSliderProduct = () => {

    return url.get('/slider/product')
}

const GetDetailProduct = (id) => {

    return url.get(`/produk/${id}`)
}

const GetProfile = (token) => {
    let config = {
        headers: {
            token: token,
        }
    }
    return url.get('/profile', config)
}

const EditProfile = (params, token) => {
    let config = {
        headers: {
            token: token,
        }
    }
    return url.post("/profile", params, config);
}

const EditProfileImage = (params, token) => {
    let config = {
        headers: {
            token: token,
        }
    }
    return url.post("/profile/image", params, config);
}

const GetKonsultasi = (token) => {
    let config = {
        headers: {
            token: token,
        }
    }
    return url.get('/konsultasi-field', config)
}
const GetKonsultasiLatest = (token) => {
    let config = {
        headers: {
            token: token,
        }
    }
    return url.get('/konsultasi-latest', config)
}

const GetKonsultasiList = (token) => {
    let config = {
        headers: {
            token: token,
        }
    }
    return url.get('/konsultasi', config)
}

const GetKonsultasiDetail = (token, id) => {
    let config = {
        headers: {
            token: token,
        }
    }
    return url.get(`/konsultasi/${id}`, config)
}

const PostKonsultasi = (token, params) => {
    let config = {
        headers: {
            token: token
        }
    }
    return url.post('/konsultasi/save', params, config)
}

const GetResep = (token) => {
    let config = {
        headers: {
            token: token,
        }
    }
    return url.get('/resep', config)
}

const GetResepDetail = (token, id) => {
    let config = {
        headers: {
            token: token,
        }
    }
    return url.get(`/resep/${id}`, config)
}

const state = {
    auth: { POST: { token: localStorage.getItem('user-token') } }
};

const apiCall = ({ url, method }) =>
    new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                resolve(state[url][method || "GET"]);
            } catch (err) {
                reject(new Error(err));
            }
        }, 1000);
    });

export { Register, VerifikasiOtp, Login, LoginGoogle,LoginFB, ResendOtp, Forgot, apiCall, GetProduct, GetSlider, GetSliderProduct, GetProfile, EditProfile, GetDetailProduct, GetKonsultasi, GetResep, EditProfileImage, GetResepDetail, GetKonsultasiList, GetKonsultasiDetail, GetKonsultasiLatest, PostKonsultasi };
